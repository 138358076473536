import { Box, Stack, VStack } from '@chakra-ui/react';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';

import { WLButton, WLH2, WLLink, WLText } from '../../../design-library';
import Overline from '../../../design-library/Overline';
import { LandingPageContext } from '../ClaimsForm/FormStepper/context/StepContext';
import { handleRootScroll } from '../ClaimsForm/FormStepper/helpers/helpers';
import * as Styles from './ClaimsLandingPage.module.scss';

interface ClaimsLandingPageProps {
  type: 'begin' | 'end';
}

const ClaimsLandingPage: React.FC<ClaimsLandingPageProps> = ({ type }) => {
  React.useEffect(() => {
    handleRootScroll();
    return () => {};
  }, []);

  const result = useStaticQuery(graphql`
    query HeroListQuery {
      file(name: { eq: "Claims" }) {
        id
        childImageSharp {
          gatsbyImageData(placeholder: DOMINANT_COLOR)
        }
      }
      contentfulList(name: { eq: "Claim Form - What To Expect" }) {
        id
        name
        listItems {
          id
          name
          headline
          content {
            raw
          }
        }
      }
    }
  `);

  const HeroImage = getImage(result.file);

  return (
    <React.Fragment>
      <VStack
        as="section"
        justifyContent="center"
        w="full"
        maxW="562"
        paddingX={{ base: '19px', lg: 'unset' }}
        paddingTop={{ base: '56px', lg: '119px' }}
        mb={type === 'begin' ? { base: '96px', lg: '160px' } : { base: '40px' }}
        spacing={10}
      >
        <Overline marginBottom={6}>
          {type === 'begin' && 'Here for you in this difficult time'}
          {type === 'end' && 'We appreciate you'}
        </Overline>
        {type === 'begin' && (
          <WLH2
            fontSize={{ base: '32px', lg: '56px' }}
            color="wl-blue.navy"
            lineHeight={{ base: '36px', lg: '64px' }}
            textAlign="center"
            style={{ marginTop: '0px', marginBottom: '24px' }}
          >
            Claim Notification
          </WLH2>
        )}
        {type === 'end' && (
          <WLH2
            fontSize={{ base: '32px', lg: '56px' }}
            color="wl-blue.navy"
            lineHeight={{ base: '36px', lg: '64px' }}
            letterSpacing={{ base: '-.035em', lg: '-.025em' }}
            textAlign="center"
            style={{ marginTop: '0px', marginBottom: '24px' }}
          >
            Thank You
          </WLH2>
        )}
        {type === 'begin' && (
          <WLText
            fontSize={['md', null, 'lg']}
            color="#3D3C3C"
            textAlign="center"
            style={{ marginTop: 0, marginBottom: 6 }}
          >
            We are sorry to hear about the loss of a loved one, and we are here
            to help.
          </WLText>
        )}
        {type === 'end' && (
          <WLText
            as="p"
            fontSize={{ base: 'md', lg: 'lg' }}
            lineHeight="22px"
            color="#3D3C3C"
            textAlign="center"
            style={{ marginTop: 0, marginBottom: 6 }}
          >
            Thank you for submitting your claim notification. If there is
            additional information needed to process the claim, we will contact
            the beneficiary of record within 10 business days of receiving this
            form.
          </WLText>
        )}
        {type === 'begin' && (
          <>
            <LandingPageContext.Consumer>
              {(context) => (
                <Box
                  display="flex"
                  width="full"
                  flexDirection="column"
                  alignItems={'center'}
                  gap={4}
                >
                  <WLText as="b" fontSize={['sm', null, 'md']}>
                    For Life Insurance and Annuity claims:
                  </WLText>
                  <WLButton
                    colorScheme="wl-blue"
                    variant="primary"
                    onClick={() => {
                      context.handleShowClaimsForm();
                    }}
                  >
                    Begin a Claim
                  </WLButton>
                </Box>
              )}
            </LandingPageContext.Consumer>
            <VStack
              flexDirection="column"
              justify={'flex-start'}
              align="center"
              maxW="526px"
              minH="129px"
              bg="#FAFCFD"
              border="1px solid #B5D5F4"
              borderRadius="16px"
              padding="32px 24px"
              spacing="40px"
            >
              <WLText fontSize="sm" textAlign={'center'} lineHeight="20px">
                <strong>Please note:</strong> The proceeds will be paid
                according to our policy records. If additional information is
                needed after filing the claim, we will contact the beneficiary
                listed in our policy records within 10 business days.
              </WLText>
            </VStack>
          </>
        )}
      </VStack>
      <VStack
        as="section"
        justifyContent="center"
        w="full"
        maxW="936px"
        paddingX={{ base: '19px', lg: 'unset' }}
        spacing="56px"
      >
        <VStack spacing={4}>
          <WLText
            fontSize={['sm', null, 'md']}
            textAlign="center"
            lineHeight={5}
          >
            {type === 'begin'
              ? 'If you prefer not to submit a claim notification online or have any other questions you may:'
              : 'If you have any questions you may:'}
          </WLText>
          <Stack
            direction={{ base: 'column', md: 'row' }}
            justify={'center'}
            align="center"
            spacing={4}
          >
            <WLButton
              colorScheme={'wl-blue'}
              variant={type === 'begin' ? 'outline' : 'primary'}
              href={'/find/rep'}
            >
              Contact a Representative
            </WLButton>
            <Box
              borderRadius="99px"
              w="20px"
              h="20px"
              border="1px solid #B7B5B3"
              fontSize="11px"
              fontWeight={'bold'}
              textAlign="center"
            >
              or
            </Box>
            <WLButton
              textTransform="capitalize"
              colorScheme={'wl-blue'}
              variant={type === 'begin' ? 'outline' : 'primary'}
              href={'tel:18002253108'}
            >
              Call 1-800-225-3108
            </WLButton>
          </Stack>
        </VStack>
        {type === 'begin' && (
          <>
            <Stack
              spacing={2}
              direction={{ base: 'column' }}
              align="center"
              justify="center"
            >
              <WLText
                as="p"
                fontSize={'15px'}
                lineHeight="20px"
                color="#3D3C3C"
                textAlign="center"
                marginBottom={0}
              >
                For Medicare Supplement claims:{`  `}
                <WLLink
                  to={'tel:18008941317'}
                  textDecoration={'none'}
                  fontWeight={'bold'}
                  fontSize={{ base: 'sm', md: 'md' }}
                  display={{ base: 'block', md: 'inline' }}
                  color={'#00549F'}
                >
                  1-800-894-1317
                </WLLink>
              </WLText>
              <WLText
                fontSize={'15px'}
                lineHeight="20px"
                color="#3D3C3C"
                textAlign="center"
                marginBottom={0}
              >
                For Accidental Death claims: {`  `}
                <WLLink
                  to={'tel:18004018691'}
                  textDecoration={'none'}
                  fontWeight={'bold'}
                  fontSize={{ base: 'sm', md: 'md' }}
                  display={{ base: 'block', md: 'inline' }}
                  color={'#00549F'}
                >
                  1-800-401-8691
                </WLLink>
              </WLText>
            </Stack>
          </>
        )}
      </VStack>
      <Box
        as="div"
        className={Styles.imageWrapper}
        w="full"
        h={{ base: '194px', sm: 'auto', lg: '746px' }}
        position="relative"
      >
        <Box
          as="div"
          bgGradient={`linear(to-b, rgba(255,255,255,1), 33%, rgba(255,255,255,0))`}
          w="100%"
          minH="91px"
          position="absolute"
          top="0"
          bottom="0"
          left="0"
          right="0"
          zIndex="1"
        />
        {HeroImage && (
          <GatsbyImage
            image={HeroImage}
            alt="a beautiful sunset over a rolling mountain vista"
          />
        )}
      </Box>
    </React.Fragment>
  );
};

export default ClaimsLandingPage;
